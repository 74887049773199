import axios, { AxiosResponse } from "axios";
import { ElMessage } from "element-plus";

// export const BaseURL = "http://localhost:9090/api";
export const BaseURL = "https://explore-api.txygame.net/api";

const http = axios.create({
    baseURL: BaseURL,
    timeout: 15 * 1000,
    headers: {
        "Content-Type": "application/json;charset=utf-8",
    },
});

http.interceptors.request.use(
    (config) => {
        config.headers = config.headers || {};

        if (localStorage.getItem("token")) {
            config.headers.Authorization = `Bearer ${localStorage.getItem("token")}`;
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

http.interceptors.response.use(
    (response: AxiosResponse) => {
        if (response.data.code !== 0) {
            ElMessage.error(response.data.message);

            if (response.data.code >= 40001 && response.data.code <= 40005) {
                localStorage.removeItem("token");
            }

            return;
        }

        return response.data;
    },
    (error) => {
        const response = error.response;

        switch (response?.status) {
            case 401:
                ElMessage.error("token失败");
                break;
            case 403:
                ElMessage.error("没有权限");
                break;
            case 500:
                ElMessage.error("服务器错误");
                break;
            default:
                ElMessage.error("服务器无响应");
                break;
        }

        return Promise.reject(error);
    }
);

export default http;
